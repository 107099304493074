// LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onLogin, isLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Your simple authentication logic (replace with a secure authentication mechanism)
    const isCredentialsValid = username === 'admin@orisys' && password === 'Adm1n0r!sys';

    if (isCredentialsValid) {
      onLogin();
      navigate('/qa-list'); // Navigate to QAList after successful login
    } else {
      alert('Invalid username or password. Please try again.');
    }
  };

  return (
    <div className='login-full-wrap'>
    <div className="login-form card">
      <div class="card-header">
        <div className='thumbnail-wrap'>
          <img src="images/bot-character.png" alt="bot-image" />
        </div>
        <div class="title-wrap">
          <h5>The <span className='text-important'>future</span> is here. Say <span className='hello-text'>hello</span> to our chat bot.</h5>
        </div>
        <div class="sub-title-wrap">
          <p>Get your information at your fingertip</p>
        </div>
      </div>
      <div class="card-body">
        <div className='row gy-4'>
          <div className='col-lg-12'>
            <div class="input-group">
              <span class="input-group-text"><i className="fa fa-user"></i></span>
              <input
              placeholder="Username"
              className='form-control'
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <div class="input-group">
              <span class="input-group-text"><i className="fa fa-lock"></i></span>
              <input
                placeholder="Password"
                className='form-control'
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <button className='primary-btn btn btn-primary login-btn' onClick={handleLogin}>
            {isLoggedIn ? 'Logout' : 'Login'}
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default LoginForm;
