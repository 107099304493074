
// export default FileContentEditor;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FileContentEditor = ({ filename, onSave }) => {
  const [editedContent, setEditedContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await axios.get(`http://143.110.253.46:5000/get_file_content?filename=${filename}`);
        setOriginalContent(response.data.content || '');
        setEditedContent(response.data.content || '');
      } catch (error) {
        console.error('Error fetching file content:', error);
        alert('Error fetching file content');
      }
    };

    fetchFileContent();
  }, [filename]);

  const handleSave = async () => {
    try {
      // Send the edited content as text in the request body
      await axios.post('http://143.110.253.46:5000/edit_file_content', `filename=${filename}&new_content=${encodeURIComponent(editedContent)}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'  // Use x-www-form-urlencoded for plain text data
        }
      });
      setOriginalContent(editedContent);
      onSave(filename, editedContent);
      alert('File content updated successfully');
    } catch (error) {
      console.error('Error updating file content:', error);
      alert('Error updating file content');
    }
  };

  return (
    <div>
      <h2>{filename}</h2>
      <textarea
        value={editedContent}
        onChange={(e) => setEditedContent(e.target.value)}
        rows={10}
        cols={50}
      />
      <br />
      <button onClick={handleSave}>Update</button>
    </div>
  );
};

export default FileContentEditor;
