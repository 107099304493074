// ChatPopup.js

import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark, faPaperPlane, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { motion } from "framer-motion";
import { Routes, Route } from 'react-router-dom';
import './container.css';

const urlRegex = /(?:https?|ftp):\/\/[\n\S]+/gi;
const styles = `


.typing-outer {
  min-height: 20px; /* Adjust the minimum height as needed */
  text-align: start;
}
  .chat-container {
    overflow: hidden;
  }
  .clear-chat-modal {
    position: absolute;
    bottom: 0;
    width: 200px;
    right: -35px;
  }
.modal-content {
  display: flex !important;
  flex-direction: row-reverse !important;
}
  .text-button {
    padding: 7px;
    border-radius: 8px;
    border: none !important;
    color: #fff;
    background: -webkit-linear-gradient(to right, #5D5FEF, #9C65FF);
    background: linear-gradient(to right, #5D5FEF, #9C65FF);
  }
  .right-side {
    display: flex;
    flex-direction: row-reverse;
  }
  .close {
    padding: 2px 8px 2px 18px;
    background: #e8f6fe;
    font-size: 18px;
    cursor: pointer;
}
.chat {
  margin-top:5px !important;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.typing-animation {
  /* Style for the typing animation */
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333; /* Change the color as needed */
  animation: typing 1s infinite alternate;
}

@keyframes typing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.chat-container p {
  font-weight: bold;
}

/* Style <ol> tags as numbered lists */
.chat-container ol {
  list-style-type: decimal; /* Use decimal numbering */
  margin-left: 20px; /* Add some indentation */
}

/* Style <ul> tags as bulleted lists */
.chat-container ul {
  list-style-type: disc; /* Use disc bullets */
  margin-left: 20px; /* Add some indentation */
}
`;

const ChatPopup = ({ onClose }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [showClearChatModal, setShowClearChatModal] = useState(false);
  const [hasName, setHasName] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const messagesEndRef = useRef(null);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  const clearChat = () => {

    const defaultMessage = chatHistory.find(chat => chat.role === 'bot' && chat.message === 'Hi, what is your name?');
    setChatHistory([defaultMessage]);
    localStorage.setItem('chatHistory', JSON.stringify([defaultMessage]));

    // Reset the flag indicating if the user's name has been obtained
    setHasName(false);

    setShowClearChatModal(false);
    scrollToBottom();
  };

  const handleCloseChat = () => {
    onClose();
  };

  const handleButtonClick = (buttonText, buttonTitle) => {
    // Check if the button has a title
    if (buttonTitle) {
      // If the button has a title, use the title as the action
      setUserInput(buttonTitle);
    } else {
      // If the button doesn't have a title, use the button text as the action
      setUserInput(buttonText);
    }
  };
  useEffect(() => {
    const storedChatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
    setChatHistory(storedChatHistory);

    const hasAskedForName = localStorage.getItem('askedForName') === 'true';

    // Check if the default message is already present in the chat history
    const hasDefaultMessage = storedChatHistory.some(chat => chat.message === 'Hi, what is your name?');

    // If the default message is not present and the user's name hasn't been asked yet, add the default message
    if (!hasDefaultMessage && !hasAskedForName) {
      const defaultMessage = { role: 'bot', message: 'Hi, what is your name?', sender: 'bot', isText: false };
      setChatHistory([defaultMessage, ...storedChatHistory]); // Add the default message to the beginning of the chat history

      localStorage.setItem('askedForName', 'true');
      localStorage.setItem('chatHistory', JSON.stringify([defaultMessage, ...storedChatHistory])); // Update local storage
    }

    // Check if the last message in chat history is the default message
    const lastMessage = storedChatHistory[storedChatHistory.length - 1];
    if (lastMessage && lastMessage.message === 'Hi, what is your name?') {
      setHasName(false); // Reset the flag indicating if the user's name has been obtained
    } else {
      setHasName(true); // Set the flag indicating that the user's name has been obtained
    }

    scrollToBottom();
  }, []);


  const renderMessageContent = (message, handleButtonClick) => {
    if (!message) {
      return null;
    }
    // Split the message into lines
    const lines = message.split('\n');
    // console.log("Message is:", message);
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the message into parts based on URLs
    const parts = message.split(urlRegex);

    return (
      <div className="message-content">
        {/* Map through the lines to render each one */}
        {lines.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
        {/* Map through the parts to render each one */}
        {parts.map((part, index) => {
          if (part.match(urlRegex)) {
            // If the part is a URL, render it as a clickable link
            return (
              <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          } else {

            // If the part is not a URL, render it as regular text


          }
        })}

      </div>
    );

  };

  const sendMessage = async () => {
    setIsBotTyping(true);
    const trimmedInput = userInput.trim();


    if (!trimmedInput) {

      setChatHistory((prevChat) => [
        ...prevChat,
        { role: 'bot', message: 'Please type a message.', sender: 'bot', isText: false },
      ]);

      setUserInput('');
      setIsBotTyping(false);
      return;
    }
    // Clear the user input field immediately after sending the message
    setUserInput('');
    setChatHistory((prevChat) => [
      ...prevChat,
      { role: 'user', message: userInput, sender: 'user', isText: false },
    ]);

    try {
      let botResponse;
      let botResponsePlainText;

      if (!hasName) {
        const userName = trimmedInput.split(' ')[0]; // Get the first word as the name
        if (userName) {
          const currentHour = new Date().getHours();
          let greeting;
          if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning';
          } else if (currentHour >= 12 && currentHour < 17) {
            greeting = 'Good afternoon';
          } else if (currentHour >= 17 && currentHour < 22) {
            greeting = 'Good evening';
          } else {
            greeting = 'Good night';
          }
          botResponse = `${greeting} ${userName}, how can I assist you today?`;
          setHasName(true); // Set the flag to indicate that the name has been obtained
        }
      } else if (trimmedInput.toLowerCase().includes('what is your name', 'Who are you')) {
        botResponse = "I'm a chatbot. How can I assist you today?";
      }
      else if (
        ['hi', 'hello', 'hey', 'hi there', 'hai', 'hii'].some(phrase => trimmedInput.toLowerCase().startsWith(phrase))
      ) {
        botResponse = "Hi, How can I assist you today?";
      }
      else if (
        ['Good morning'].some(phrase => trimmedInput.toLowerCase().startsWith(phrase))
      ) {
        botResponse = "How can I assist you today?";
      }
      else if (
        ['deposit schemes', 'what are the deposit schemes'].some(phrase => trimmedInput.toLowerCase().startsWith(phrase))
      ) {
        botResponse = "Karnataka Gramin bank offers various deposit schemes including:\n" +
          "1. Saving Bank Deposit\n" +
          "2. Current Account Deposit\n" +
          "3. Kamadhenu Deposit\n" +
          "4. Fixed Deposit\n" +
          "5. Recurring Deposit\n";
      }
      else {
        const response = await fetch('http://143.110.253.46:5000/get_response', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_message: userInput }),
        });


        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        botResponse = data.bot_response;
        // console.log(botResponse);
        if (!botResponse) {
          botResponse = "Sorry, I can't answer it at the moment.";
        }
      }

      // Extract URLs from the bot response
      const urlMatches = botResponse.match(/\bhttps?:\/\/\S+\b/g);

      const gifUrls = [];
      const httpUrls = [];
      const httpsUrls = [];
      const textUrls = [];

      if (urlMatches) {
        urlMatches.forEach((url) => {
          if (url.toLowerCase().endsWith('.gif')) {
            gifUrls.push(url);
          } else if (url.startsWith('http://')) {
            httpUrls.push(url);
          } else if (url.startsWith('https://')) {
            httpsUrls.push(url);
          } else {
            textUrls.push(url);
          }
        });
      }

      // Display GIFs and text URLs in chat history
      if (gifUrls.length > 0) {
        setChatHistory((prevChat) => [
          ...prevChat,
          { role: 'bot', message: botResponse, gifs: gifUrls, sender: 'bot', isText: false },
        ]);
      } else if (textUrls.length > 0) {
        const parts = botResponse.split(/(https?:\/\/\S+)/);
        const messages = parts.map((part, index) => ({
          role: 'bot',
          message: part,
          sender: 'bot',
          isText: index % 2 !== 0 && textUrls.includes(part),
        }));
        setChatHistory((prevChat) => prevChat.filter((chat) => !chat.isTyping));
      }
      else {
        setIsBotTyping(false);
        // console.log('Bot Response:', botResponse);
        setChatHistory((prevChat) => [
          ...prevChat,
          { role: 'bot', message: botResponse, sender: 'bot', isText: false },
        ]);

      }


      scrollToBottom();

    } catch (error) {
      console.error('Error fetching bot response:', error);

    }

  };

  useEffect(() => {
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
  }, [chatHistory]);

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
    scrollToBottom();
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleOpenClearChatModal = () => {
    setShowClearChatModal(true);
  };

  const handleCloseClearChatModal = () => {
    setShowClearChatModal(false);
  };

  const handleConfirmClearChat = () => {
    clearChat();
    handleCloseClearChatModal();
  };

  return (
    <>
      <style>{`
        .chat-container {
          overflow: hidden;
        }
        .clear-chat-modal {
          position: absolute;
          bottom: 0;
          width: 200px;
          right: -35px;
        }
        .modal-content {
          display: flex !important;
          flex-direction: row-reverse !important;
        }
        .text-button {
          padding: 7px;
          border-radius: 8px;
          border: none !important;
          color: #fff;
          background: -webkit-linear-gradient(to right, #5D5FEF, #9C65FF);
          background: linear-gradient(to right, #5D5FEF, #9C65FF);
        }
        .right-side {
          display: flex;
          flex-direction: row-reverse;
        }
        .close {
          padding: 2px 8px 2px 18px;
          background: #e8f6fe;
          font-size: 18px;
          cursor: pointer;
        }
        .chat {
          margin-top:5px !important;
        }
      `}</style>
      <div className='chat-container-outer' style={{ position: 'fixed', bottom: '0', right: '10px', zIndex: '999', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <div className="whatsapp-like">
          <div className="chat-container">
            <Routes>
              <Route
                path="/"
                element={
                  <>

                    <div className='card chat-card'>
                      <div className='card-header'>
                        <div className="left-side">
                          <div className='bot-img'>
                            <img src="images/bot-avatar.png" alt="avatar-img" />
                          </div>
                          <h4>KGBot</h4>
                        </div>
                        <div className="right-side">
                          <button onClick={handleCloseChat} className="btn btn-default close-btn">
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                          <div className="dropdown" style={{ marginLeft: '10px' }}>
                            <button onClick={clearChat} className="btn btn-default">
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='encrypt-main-text'>
                        <p>This is a private message, between you and KGBot.<br />This chat is end-to-end encrypted.</p>
                      </div>
                      <div className='card-body' style={{ height: '36vh' }}>
                        <div className="chat" style={{ height: '100%' }}>
                          {chatHistory.map((chat, index) => (
                            <motion.div // Wrap your message container with motion.div for animation
                              key={index}
                              className={`message-container ${chat.role === 'user' ? 'user-message' : 'bot-message'}`}
                              initial={{ scale: 0 }} // Initial animation state (scale down to 0)
                              animate={{ scale: 1, transition: { duration: 0.5 } }} // Animate to scale 1 over 0.5 seconds
                            >
                              <img className='bot-img' src="images/bot-avatar.png" alt="bot-icon" />
                              <div className="message-content-inner">
                                {chat.gifs && chat.gifs.length > 0 ? (
                                  // If there are GIFs, display them along with the message text
                                  <div className="gif-container">
                                    {chat.gifs.map((gif, gifIndex) => (
                                      <img
                                        key={gifIndex}
                                        src={gif}
                                        alt={`gif-${gifIndex}`}
                                        className="gif-image"
                                      />
                                    ))}
                                    <div className="message-content">
                                      {chat.message.replace(
                                        /\bhttps?:\/\/\S+\.(gif)\b/g,
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-content">
                                    {renderMessageContent(chat.message, handleButtonClick)}
                                    {console.log("Message:", chat.message)}
                                  </div>

                                )
                                }
                              </div>
                            </motion.div >
                          ))}
                          {/* Typing animation */}
                          {isBotTyping && (
                            <div className="typing-outer">
                              <img src="typing.gif" className="animation-typing" alt="..Typing" />
                              {/* <div class="typing-animation">Typing...</div> */}

                            </div>
                          )}
                          <div ref={messagesEndRef} />
                        </div>
                      </div>
                      <div className='card-footer'>
                        <div className="input-container">
                          <input
                            className="form-control"
                            type="text"
                            id="user-input"
                            placeholder="Type a message..."
                            value={userInput}
                            onChange={handleUserInput}
                            onKeyDown={handleEnterKey}
                          />
                          <motion.div // Wrap your send button with motion.div for animation
                            className="btn-group"
                            initial={{ scale: 0 }} // Initial animation state (scale down to 0)
                            animate={{ scale: 1, transition: { delay: 0.5 } }} // Animate to scale 1 after 0.5 seconds delay
                          >
                            <button className="btn btn-primary send-btn" onClick={sendMessage}>
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPopup;
