// App.js
import React, { useState } from 'react';
import ChatPopup from './components/ChatPopup';
import LoginForm from './components/LoginForm';
import QAList from './components/QAList';
import Home from './pages/Home'; // Import the Home component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showChatPopup, setShowChatPopup] = useState(false);

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);
    setShowChatPopup(false);
  };

  const handleToggleChatPopup = () => {
    setShowChatPopup(prev => !prev);
  };

  return (
    <Router>
      <div>
        <Routes>
          {/* Define routes for Home, LoginForm, and QAList */}
          <Route
            path="/"
            element={
              <div>
                {/* ChatPopup component */}
                {showChatPopup && (
                  <ChatPopup
                    onClose={() => setShowChatPopup(false)}
                    isLoggedIn={isLoggedIn}
                    onLogin={handleToggleChatPopup}
                    onLogout={() => setIsLoggedIn(false)}
                    onSuccessfulLogin={handleSuccessfulLogin}
                  />
                )}
                {/* Your Home component JSX content */}
                <Home />
                {/* Button container with inline styles */}
                <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999', border:'none', background:'none' }}>
                  {/* Show chat button if not logged in and chat popup is not shown */}
                  {!isLoggedIn && !showChatPopup ? (
                    <button
                      onClick={handleToggleChatPopup}
                      style={{ width: '60px', height: '60px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', border:'none', background:'none' }}
                      className=""
                      title="Chat"
                    >
                      <img src="images/chaticon.png" alt="chat-btn" />
                    </button>
                  ) : null}

                  {/* Show logout button and QA list button if logged in */}
                  {isLoggedIn ? (
                    <>
                      <button
                        onClick={() => setIsLoggedIn(false)}
                        className="btn btn-primary"
                        title="Logout"
                      >
                        Logout
                      </button>
                      <button
                        href="/qa-list"
                        className="btn btn-primary"
                        title="QA List"
                      >
                        QA List
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            }
          />
          <Route path="/login" element={<LoginForm onLogin={handleSuccessfulLogin} />} />
          <Route path="/qa-list" element={<QAList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
