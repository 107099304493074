import React, { useState } from 'react';

const AddQAForm = ({ onAddQA, onClose }) => {
  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleAddQA = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('http://chatbot.oricoms.com:5000/upload_file', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setFile(null);
        onClose();
        onAddQA(); // Trigger callback to acknowledge successful addition
        alert('File uploaded successfully.'); // Display acknowledgment message
      } else {
        const data = await response.json();
        console.error('Failed to upload file:', data.error);
        alert('Failed to upload file. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="add-qa-form">
      <h2>Add a New File</h2>
      <div className="form-group-outer">
        <div className="col-lg-12">
          <input
            type="file"
            accept=".txt"
            onChange={handleFileUpload}
          />
        </div>
      </div>
      <div className="form-group-outer">
        <div className="btn-group">
          <button className="btn btn-primary primary-btn width-auto" onClick={handleAddQA}>
            Upload File
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQAForm;
