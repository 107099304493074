
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'; // Import Link component

import './assets/css/bootstrap.min.css';
import "./assets/css/style.css";
const Home = () => {
  return (
    <>
        <div className="header container-fluid bg-white shadow">
            <div className="hed-top bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block">
                            <ul className="text-white">
                                <li className="float-start p-3"><i className="bi bi-envelope"></i> info@mbankname.com</li>
                                <li className="float-start p-3"><i className="bi bi-telephone"></i> +98 7878 7878 787</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul className="text-white float-end">
                                <li className="float-start p-3"><i className="bi bi-facebook"></i></li>
                                <li className="float-start p-3"><i className="bi bi-twitter"></i></li>
                                <li className="float-start p-3"><i className="bi bi-instagram"></i></li>
                                <li className="float-start p-3"><i className="bi bi-linkedin"></i></li>
                                <li className="float-start pt-2 ms-4">
                                      <Link to="#" className="btn btn-light">Login / Sign Up</Link>
                         </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-col bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 pt-1 pb-2 align-items-center">
                            <img className="max-230" src="images1/logo.jpg" alt=""/>
                            <a  data-bs-toggle="collapse" data-bs-target="#menu" className="float-end d-lg-none pt-1 ps-3"><i className="bi pt-1 fs-1 cp bi-list"></i></a>
                        </div>
                        <div id="menu" className="col-lg-9 d-none d-lg-block">
                            <ul className="float-end mul d-inline-block">
                                <li className="float-md-start p-4"><a href="">Home</a></li>
                                <li className="float-md-start p-4"><a href="">About US</a></li>
                                <li className="float-md-start p-4"><a href="">Services</a></li>
                                <li className="float-md-start p-4"><a href="">Case Studies</a></li>
                                <li className="float-md-start p-4"><a href="">Blog</a></li>
                                <li className="float-md-start p-4"><a href="">Contact US</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-conten big-padding">
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                    <h1 className="fs-11 dfr fw-bold">Connect All your <span className="text-primary">Banking Needs</span> and Grow</h1>
                    <p className="fs-8">Transform your banking experience with Digital Money Bank. Whether you're managing your accounts, making transactions, or planning for the future, our platform provides a user-friendly and efficient solution. Join us in the journey towards financial growth and prosperity. Embrace the ease of banking with Digital Money Bank.</p>
                        <div className=" d-inline-block pt-4">
                            <button className="btn btn-light shadow-md p-3 px-5 fs-7 fw-bold">Get Quote</button>
                            <button className="btn btn-primary ms-3 fs-7 shadow-md p-3 px-5 fw-bold">Get Quote</button>
                        </div>
                    </div>
                    <div className="col-md-6 pt-4">
                        <img className="w-100" src="images1/slid.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
        
        
       <div className="what-we-do big-padding bg-white container-fluid">
           <div className="container">
               <div className="section-title row">
                   <h2>Our Services</h2>
                   <p>Welcome to our range of innovative services designed to meet your financial needs. Explore the options below and discover the convenience and security we offer.</p>
               </div>
               <div className="row mt-4 whd">
                   <div className="col-md-4 text-start mb-5">
                       <i className="bi text-primary fs-1 bi-bank"></i>
                       <h4 className="fs-6 fw-bold mt-3">Online Banking</h4>
                       <p>Experience seamless online banking with our user-friendly platform. Manage your accounts, transfer funds, and stay in control of your finances anytime, anywhere.</p>
                   </div>
                    <div className="col-md-4 text-start mb-5">
                       <i className="bi fs-1 text-primary bi-phone-flip"></i>
                       <h4 className="fs-6 fw-bold mt-3">Mobile Banking</h4>
                       <p>Take your bank with you on the go. Our mobile banking services ensure that you can access your accounts, make transactions, and stay connected using your smartphone.</p>
                   </div>
                    <div className="col-md-4 text-start mb-5">
                      <i className="bi fs-1 text-primary bi-book-half"></i>
                       <h4 className="fs-6 fw-bold mt-3">Business Plan</h4>
                       <p>Explore tailored business plans that suit your company's needs. From startups to established enterprises, we provide financial solutions to fuel your business growth.</p>
                   </div>
                     <div className="col-md-4 text-start mb-5">
                      <i className="bi fs-1 text-primary bi-currency-dollar"></i>
                       <h4 className="fs-6 fw-bold mt-3">Online Business</h4>
                       <p>Enter the world of online entrepreneurship with our comprehensive solutions. Boost your e-commerce ventures and optimize your online business for success.</p>
                   </div>
                    <div className="col-md-4 text-start mb-5">
                       <i className="bi fs-1 text-primary bi-piggy-bank"></i>
                       <h4 className="fs-6 fw-bold mt-3">Online Deposit</h4>
                       <p>Securely deposit your savings online with our reliable services. Enjoy competitive interest rates and watch your money grow with peace of mind.</p>
                   </div>
                     <div className="col-md-4 text-start mb-5">
                       <i className="bi  fs-1 text-primary bi-shield-shaded"></i>
                       <h4 className="fs-6 fw-bold mt-3">Business Insurance</h4>
                       <p>Protect your business with our comprehensive insurance plans. Safeguard your assets and investments, ensuring a resilient future for your company.</p>
                   </div>
                   
                  
               </div>
           </div>
       </div>
       <div className="features big-padding container-fluid">
        <div className="container">
            <div className="row">
                <div className="col-md-6 p-4">
                    <img className="shadow" src="images1/a1.jpg" alt=""/>
                </div>
                <div className="col-md-6 align-self-center">
    <h2 className="fs-2 fw-bolder mb-3">Ensuring Privacy, Innovation, and Security in Our Digital Banking Solutions</h2>
    <p className="mb-2">In the dynamic realm of digital finance, Digital Money Bank stands as a beacon for secure and innovative banking experiences. Our commitment to privacy and cutting-edge security technologies is unwavering. Experience financial transactions with confidence as we prioritize your digital well-being. We believe in providing a seamless and trustworthy environment for all your banking needs.</p>
    <p>Explore a world of possibilities with Digital Money Bank. Our services go beyond the ordinary, offering a blend of convenience and security. Whether it's managing your accounts or making transactions, our user-friendly platform ensures a hassle-free experience. Trust [Your Bank Name] for a future-ready approach to digital banking.</p>
</div>

            </div>
        </div>
    </div>
    <div className="features big-padding container-fluid bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <h2 className="fs-2 fw-bolder mb-3">Privacy, Innovation and Security in the Digital Payments World</h2>
                        <p className="mb-2">At Digital Monney Bank, we understand the importance of privacy in every transaction you make. With our advanced encryption protocols and stringent privacy measures, your personal and financial information remains secure at all times. Whether you're making a purchase online or transferring funds to a friend, your privacy is our top priority.</p>
                        <p>
                        Innovation is at the heart of Digital Money Bank's digital payment solutions. We continuously strive to enhance your banking experience by introducing cutting-edge technologies and intuitive features. From seamless mobile payments to contactless transactions, we're revolutionizing the way you manage your finances.
                        </p>
                    </div>
                    <div className="col-md-6 p-4">
                        <img className="shadow bg-white p-2" src="images1/a2.jpg" alt=""/>
                    </div>
                </div>
            </div>
    </div>
    <div className="mobile-cart container-fluid big-padding">
        <div className="container">
           <div className="row">
               <div className="col-md-8 align-self-center">
                    <h4 className="fs-2 fw-bold mb-3">Stay connected,  <br/>enjoy peace of mind on the Go</h4>
                    <p>Introducing Digital Money Bank's mobile app  your trusted companion for seamless banking wherever you are.</p>
                    <div className="btnrow mt-4">
                         <img src="images1/appstore.png" alt=""/>
                         <img className="ms-3" src="images1/google-play.png" alt=""/>
                    </div>
                </div>
                <div className="col-md-4 mt-4">
                    <img src="images1/moble-app.png" alt=""/>
                </div>
           </div>
            
        </div>
    </div>
    
            
        <div id="blog" className="service container-fluid px-4 bg-white py-5">
            <div className="container">
             <div className="section-title row mb-3">
                <h2 className="fw-bolder">Blog</h2>
                <p>Stay tuned for insightful updates and helpful tips in this section of our blog.</p>
            </div>
            <div className="row mt-5">
                <div className="col-lg-4 col-md-6 mb-4">
                   <div className="serv-cove rounded bg-white p-2">
                        <img src="images1/blog/blog_01.jpg" alt=""/>
                        <div className="p-2">
                            <h5 className="mt-3 fs-7 fw-bold">Unlocking Opportunities: Guide to Educational Funding Abroad for Underprivileged Students</h5>
                            <span className="fs-8">22 May 2023</span>
                            <span className="float-end fs-8"><i className="bi bi-person"></i> Sam Anderson</span>
                        </div>
                   </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                   <div className="serv-cove rounded bg-white p-2">
                        <img src="images1/blog/blog_02.jpg" alt=""/>
                        <div className="p-2">
                            <h5 className="mt-3 fs-7 fw-bold">Breaking Barriers: Scholarships for Bright Minds from Low-Income Backgrounds</h5>
                            <span className="fs-8">22 May 2023</span>
                            <span className="float-end fs-8"><i className="bi bi-person"></i> Sam Anderson</span>
                        </div>
                   </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                   <div className="serv-cove rounded bg-white p-2">
                        <img src="images1/blog/blog_03.jpg" alt=""/>
                        <div className="p-2">
                            <h5 className="mt-3 fs-7 fw-bold">Investing in Potential: Scholarships for Aspiring Students: How to Apply and Succeed</h5>
                            <span className="fs-8">22 May 2023</span>
                            <span className="float-end fs-8"><i className="bi bi-person"></i> Sam Anderson</span>
                        </div>
                   </div>
                </div>
                </div>
            </div>
        </div>
    
            
    <footer>
        <div className="inner">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 foot-about">
                        <h4>About US</h4>

                        <p>Digital money banks offer convenient, online-only banking services with lower fees and higher interest rates, catering to tech-savvy consumers while prioritizing financial inclusion for underserved individuals.</p>

                        <ul>
                            <li>23 Rose Stren Melbourn</li>
                            <li>help@digitalmoneybank.com</li>
                            <li>+91 777 333 6644</li>
                        </ul>
                    </div>

                    <div className="col-md-3 foot-post">
                        <h4>Latest Posts</h4>

                        <div className="post-row">
                            <div className="image">
                                <img src="images1/blog/blog_01.jpg" alt=""/>
                            </div>
                            <div className="detail">
                                <p>Unlocking Opportunities: Guide to Educational Funding..</p>
                            </div>
                        </div>

                        <div className="post-row">
                            <div className="image">
                                <img src="images1/blog/blog_02.jpg" alt=""/>
                            </div>
                            <div className="detail">
                                <p>"Breaking Barriers: Scholarships for Bright Minds from..</p>
                            </div>
                        </div>

                        <div className="post-row">
                            <div className="image">
                                <img src="images1/blog/blog_03.jpg" alt=""/>
                            </div>
                            <div className="detail">
                                <p>Investing in Potential: Scholarships for Aspiring Stud..</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 foot-services">
                        <h4>Top Category</h4>

                        <ul>
                            <li><a href="">Target Statergy</a></li>
                            <li><a href="">Web Analytics</a></li>
                            <li><a href="">Page Monitering</a></li>
                            <li><a href="">Page Optimization</a></li>
                            <li><a href="">Target Statergy</a></li>
                            <li><a href="">Email Marketing</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 foot-news">
                        <h4>News Letter</h4>
                        <p>Stay informed with our newsletter, delivering the latest updates, exclusive offers, and insightful articles directly to your inbox.</p>

                        <div className="input-group mb-3">
                            <input type="text text-fieldbox" className="form-control mb-0" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                            <div className="input-group-append">
                                <span className="input-group-text bg-primary" id="basic-addon2"><i className="bi text-white bi-send"></i></span>
                            </div>
                        </div>

                        <ul>
                            <li><i className="bi bi-facebook"></i></li>
                            <li><i className="bi bi-twitter"></i></li>
                            <li><i className="bi bi-instagram"></i></li>
                            <li><i className="bi bi-linkedin"></i></li>
                            <li><i className="bi bi-pinterest"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="copy">
        <div className="container">
            <a href="">2024 &copy; All Rights Reserved | Designed and Developed by OrisysIndia</a>

            <span>
                <a href=""><i className="fab fa-github"></i></a>
                <a href=""><i className="fab fa-google-plus-g"></i></a>
                <a href=""><i className="fab fa-pinterest-p"></i></a>
                <a href=""><i className="fab fa-twitter"></i></a>
                <a href=""><i className="fab fa-facebook-f"></i></a>
            </span>
        </div>
    </div>

        
    </>
  );
};

export default Home;
