import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';

import AddQAForm from './AddQAForm';
import FileContentEditor from './FileContentEditor';

const QAList = ({ onClose }) => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddForm, setShowAddForm] = useState(true);
  const [showList, setShowList] = useState(false); // Initially set to false
  const [activeBlock, setActiveBlock] = useState(null);
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [editFile, setEditFile] = useState(null);
  const itemsPerPage = 10;

  // Define the fetchFileContent function here
  const fetchFileContent = async (filename) => {
    try {
      const response = await axios.get(`http://143.110.253.46:5000/get_file_content?filename=${filename}`);
      setFileContent(response.data.content || ''); // Assuming the content is returned in the response
    } catch (error) {
      console.error('Error fetching file content:', error);
      setFileContent(''); // Set an empty content if there's an error
    }
  };

  useEffect(() => {
    fetchFileList();
  }, [currentPage, showAddForm, showList, activeBlock]);

  const fetchFileList = async () => {
    try {
      const response = await axios.get('http://143.110.253.46:5000/get_file_list');
      setFileList(response?.data?.fileList || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching file list:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (editFile) {
      fetchFileContent(editFile);
    }
  }, [editFile]);

  
  const handleDelete = async (filename) => {
    try {
      const response = await axios.post('http://143.110.253.46:5000/delete_file', { filename });
      console.log(response.data.message);
      fetchFileList();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleBlockVisibility = (block) => {
    // Toggle the visibility of the file list
    if (block === 'showData') {
      setShowList(!showList); // Toggle the file list visibility
      setShowAddForm(false); // Hide the add QA form if it's open
      setCurrentPage(1); // Reset pagination to first page
    } else {
      // Your other toggle logic here for other blocks (if needed)
    }
  };

  const handleEdit = (filename) => {
    // Set the filename to edit
    setEditFile(filename);
  };

  const handleLogout = () => {
    navigate('/login');
    // You can add additional logout logic here if needed
  };

  const handleTrainModel = async () => {
    try {
      const response = await axios.post('http://143.110.253.46:5000/train_model');
      console.log('Model trained successfully');
    } catch (error) {
      console.error('Error training model:', error);
    }
  };
// Function to toggle the visibility of the add form
const toggleAddFormVisibility = () => {
  setAddFormVisible(!addFormVisible);
};
return (
  <div className='qa-full-wrap'>
    <div className="qa-list-container">
      <div className="qa-list-header">
        <h2>File List</h2>

        <div className='btn-group'>
          {/* Add QA button */}
          <button
            className={`btn btn-primary ${activeBlock === 'addQA' ? 'active' : ''}`}
            onClick={toggleAddFormVisibility} // Toggle visibility on click
            title="Add QA Pair"
          >
            <i className={`fa ${addFormVisible ? 'fa-minus' : 'fa-plus'}`}></i> {/* Change icon based on visibility */}
            <span className='btn-text'>{addFormVisible ? 'Hide Add Form' : 'Add Form'}</span> {/* Change text based on visibility */}
          </button>
          {/* Show Data button */}
          <button
            className={`btn btn-primary ${activeBlock === 'showData' ? 'active' : ''}`}
            onClick={() => toggleBlockVisibility('showData')}
            title={showList ? "Hide Data" : "Show Data"} // Change title based on state
          >
            <i className={`fa ${showList ? 'fa-eye-slash' : 'fa-file'}`}></i> {/* Change icon based on state */}
            <span className='btn-text'>{showList ? 'Hide Data' : 'Show Data'}</span> {/* Change text based on state */}
          </button>



          {/* Logout button */}
          <button
            className={`btn btn-primary ${activeBlock === 'logout' ? 'active' : ''}`}
            onClick={handleLogout}
            title="Logout"
          >
            <i className='fa fa-sign-out'></i> <span className='btn-text'>Logout</span>
          </button>

          {/* Train Model button */}
          <button
            className="btn btn-primary"
            onClick={handleTrainModel}
            title="Train Model"
          >
           <i class="fa-solid fa-code-branch" onClick={handleTrainModel}
            title="Train Model"></i> <span className='btn-text'>Train Model</span>
          </button>
        </div>
      </div>

      {/* Add QA form */}
      {addFormVisible && (
        <AddQAForm
          onAddQA={() => {
            setShowAddForm(false);
            fetchFileList();
          }}
          onClose={() => setShowAddForm(false)}
        />
      )}

{showList && (
          <div className="file-list">
            {loading ? (
              <p className='empty-text'>Loading...</p>
            ) : (
              <>
                <div className='table-wrap'>
                  <table>
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fileList.map((filename, index) => (
                        <tr key={index}>
                          <td>
                            {filename}
                          </td>
                          <td>
                            <button onClick={() => handleEdit(filename)}>Edit</button>
                            <button onClick={() => handleDelete(filename)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={fileList.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText={<i className='fa fa-chevron-left'></i>}
                  nextPageText={<i className='fa fa-chevron-right'></i>}
                  containerClassName="pagination"
                  activeClassName="active"
                  itemClassFirst='firstpage'
                  itemClassLast='lastpage'
                  itemClassPrev='prevpage'
                  itemClassNext='nextpage'
                />
              </>
            )}
          </div>
        )}
      </div>
      {/* Render FileContentEditor if a file is being edited */}
      {editFile && (
        <FileContentEditor
          filename={editFile}
          content={fileContent} // Pass the file content here
          onSave={(filename, editedContent) => {
            console.log(`Content of ${filename} updated to: ${editedContent}`);
            setEditFile(null);
          }}
        />
      )}
    </div>
  );
};

export default QAList;